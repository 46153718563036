<template>
  <div class="web-container">
    <!--页面顶部-->
    <div class="page-header">
      <div class="header-container">
        <div class="web-title flex flex-start">
          <img :src="webConfig.logoUrl" alt="" class="logo">
          <div class="title-name">{{ webConfig.systemName }}</div>
        </div>
      </div>
    </div>
    <div class="nav-container">
      <span :class="navIndex==='1'?'li active':'li'" @click="clickNavLi('1')">中心介绍</span>
      <span :class="navIndex==='2'?'li active':'li'" @click="clickNavLi('2')">项目介绍</span>
      <!--      <span class="li" @click="clickNavLi('3')">教学团队</span>-->
      <span :class="navIndex==='4'?'li active':'li'" @click="clickNavLi('4')">友情链接</span>
      <span :class="navIndex==='5'?'li active':'li'" style="color: #4093f9" @click="clickNavLi('5')">体验教学系统</span>
    </div>
    <!--轮播图-->
    <div class="focus-container ">
      <el-carousel :interval="6000" height="520px">
        <el-carousel-item v-for="item in webConfig.focusList" :key="item" style="text-align: center">
          <el-image
              style="width: 1920px;height:520px;"
              :src="item"
              fit="contain"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="video-container">
      <el-image src="http://resouce.cdzyhd.com/29616876-243d-49fa-8e60-dd288a3aaeef.jpg" fit="cover" style="width: 100%;cursor: pointer" @click="clickVideoImg"></el-image>
    </div>
    <!--中心介绍-->
    <a href="#" name="center"></a>
    <div class="li-container content-container">
      <el-divider content-position="center" class="content-title">中心介绍</el-divider>
      <div class="content-html html-view">
        <p>
          重庆城市管理职业学院思政课虚拟仿真体验教学中心建立于2022年9月。中心依托物联网、云计算、人工智能等新一代信息技术，大力整合教师资源、争取社会资源、完善硬件资源，将虚拟仿真技术引入思想政治教育教学改革，通过“沉浸式、体验式、互动式”教学方式，充分调动学生学习积极性、主动性、参与性，推动学校思想政治课教学与现代信息技术有机融合。</p>
        <p>
          中心构建有中共一大、飞夺泸定桥、劳模精神、职业理想和职业道德、习近平总书记在重庆的足迹、党的精神谱系等虚拟仿真实验，使学生在直观、生动、形象、逼真的三维场景中，通过虚拟仿真技术和交互操作“亲身体验”历史，并对历史现象和事件进行独立自主地分析、判断，从而实现思政育人春风化雨，润物无声的教学效果。</p>
        <p>
          中心依托建设单位——重庆城市管理职业学院马克思主义学院，是中共重庆市委宣传部、中共重庆市委教育工委、重庆市教委确定的“市级重点思想政治理论课教学科研机构”，学院党支部是第二批“全国党建工作样板支部”，整体水平居于重庆市高职院校前列，在全国具有较强社会影响力。学院建有教育部高校示范马克思主义学院和优秀教学科研团队1个、重庆市高校黄大年式教师团队1个、重庆市高校思想政治理论课名师工作室1个、重庆市高校思想政治理论课教学科研示范团队1个；荣获国家级教学成果二等奖1项、重庆市教学成果一等奖2项、校级教学成果一等奖4项；建设重庆市市级一流课程2门、精品在线开放课程2门。</p>
        <p>
          中心现有教师49人，均为中共党员，其中有教授职称4人、副教授职称15人。1名教师荣获2021年教育部高校思政课教学展示活动特等奖，1个教师团队获2020年全国职业院校技能大赛教学能力比赛一等奖。近年来，教师先后荣获全国教学竞赛二等奖3项、三等奖1项，重庆市教学竞赛特等奖1项、一等奖5项、二等奖3项、三等奖2项。同时，有教育部高校思政课教指委委员1人、全国高校思想政治理论课教师影响力人物1人、全国高校思想政治理论课教学能手1人、重庆市教书育人楷模3人、重庆市五一劳动奖章获得者1人、重庆市中青年骨干教师1人、重庆市高校优秀思想政治教育工作者1人、重庆市高校优秀中青年思想政治理论课教师择优资助计划人选3人。</p>
        <p>
          中心正紧紧围绕立德树人根本任务，以加快构建思政教育大资源平台为目标，以大力推动思政教育信息化为抓手，秉持开放、共享、交互、协作原则，努力把中心建成集“教育教学、培训研修、创新发展”于一体的高水平思政课虚拟仿真体验教学中心和高职院校思想政治工作队伍培训研修示范基地，进一步提升我校党建和思想政治工作的实效性、吸引力和影响力。</p>
      </div>
    </div>
    <!--项目介绍-->
    <a href="#" name="experiment"></a>
    <div class="content-container experiment-container">
      <el-divider content-position="center" class="content-title">项目介绍</el-divider>
      <div class="experiment-list">
        <div class="sub-list flex flex-start flex-wrap">
          <div class="sub-li" v-for="(experimentItem,experimentIndex) in experiments.list">
            <img :src="experimentItem.product_iconLeft" alt="" class="left-icon"
                 v-if="experimentItem.product_iconLeft">
            <div class="bg">
              <img :src="experimentItem.product_bg+'?imageView2/1/w/290/h/183/q/75'">
            </div>
            <div class="info">
              <img class="avatar" :src="experimentItem.product_icon" alt="">
              <div class="flex flex-dr flex-center">
                <div class="title">{{ experimentItem.name }}</div>
                <div class="des-main word-hr ellipsis">{{ experimentItem.subName }}</div>
                <!--最多3行 每行18字 第三行超出省略号显示-->
                <div class="des word-hr ellipsis" v-html="experimentItem.description"></div>
                <div class="buttons">
                  <a class="button" href="javascript:void(0)"
                     @click="ExperimentMethods().clickViewExperiment(experimentItem)">查看详情</a>
                </div>
              </div>
            </div>
          </div>
          <div class="sub-li more-li">
            <div class="bg">
              <img src="https://resouce.cdzyhd.com/08aba1b4-255f-4540-8089-f947243218d5.png">
            </div>
            <div class="info">

            </div>
          </div>
        </div>
      </div>
    </div>
    <!--产品介绍弹窗-->
    <div v-if="experimentInfo.dialog" class="my-dialog-container flex flex-dr flex-center experiment-dialog">
      <div class="mask" @click="ExperimentMethods().closeExperimentDialog()"></div>
      <div class="dialog-box">
        <div class="bg"></div>
        <img src="@/assets/icon/dialog_close.png" alt="关闭" class="close-icon"
             @click="ExperimentMethods().closeExperimentDialog()">
        <div class="info-box">
          <div class="header-box flex flex-start">
            <img :src="experimentInfo.experiment.product_icon" class="avatar" alt="">
            <div class="name-box">
              <div class="name">{{ experimentInfo.experiment.name }}</div>
              <div class="series">{{ experimentInfo.experiment.seriesName }}</div>
            </div>
            <div>

            </div>
          </div>
          <div class="description-box">
            <div class="title">实验简介：</div>
            <div class="content">
              <p v-for="p in experimentInfo.experiment.experimentDescription.split('\n')">{{ p }}</p>
            </div>
          </div>
          <div class="view-box flex flex-between">
            <div class="video-box" v-if="experimentInfo.experiment.product_video">
              <video id="v1" loop="" preload="" muted="" x-webkit-airplay="true" airplay="allow"
                     webkit-playsinline="true" playsinline="true" :src="experimentInfo.experiment.product_video"
                     class="video1 video"
                     draggable="true"></video>
              <img src="@/assets/product/dialog/play.png" alt="" class="play-btn"
                   @click="e=>ExperimentMethods().playVideo(e)">
            </div>
            <el-image v-if="!experimentInfo.experiment.product_video&&experimentInfo.experiment.sample!==true"
                      class="video-box-no" fit="cover"
                      :src="experimentInfo.experiment.product_info_img"
                      :preview-src-list="experimentDialogPicList"></el-image>
            <div class="img-box flex flex-around flex-wrap" v-if="experimentInfo.experiment.sample!==true">
              <el-image
                  class="img"
                  fit="cover"
                  :src="experimentInfo.experiment.product_info_img_1"
                  :preview-src-list="experimentDialogPicList">
              </el-image>
              <el-image
                  class="img"
                  fit="cover"
                  :src="experimentInfo.experiment.product_info_img_2"
                  :preview-src-list="experimentDialogPicList">
              </el-image>
              <el-image
                  class="img"
                  fit="cover"
                  :src="experimentInfo.experiment.product_info_img_3"
                  :preview-src-list="experimentDialogPicList">
              </el-image>
              <el-image
                  class="img"
                  fit="cover"
                  :src="experimentInfo.experiment.product_info_img_4"
                  :preview-src-list="experimentDialogPicList">
              </el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--友情链接-->
    <a href="#" name="link"></a>
    <div class="link-container content-container">
      <el-divider content-position="center" class="content-title">友情链接</el-divider>
      <div class="list flex flex-around flex-wrap">
        <div v-for="item in linkConfig.list" v-if="item.img" @click="clickLink(item)">
          <el-image :src="item.img" alt="" class="li" fit="contain"></el-image>
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <!--底部-->
    <div class="page-footer">
      <div class="info-container content-container">
        <div class="infos">
          <img :src="webConfig.logoUrl" alt="" class="logo">
          <p>{{ webConfig.webName }}</p>
          <p>主办：{{ webConfig.colleageName }}</p>
          <span>技术支持：成都智云鸿道信息技术有限公司<i class="blank"></i>薪火印记（北京）文化发展有限公司</span>
          <span style="cursor: pointer" @click="window.open('https://beian.miit.gov.cn/')">
            &nbsp;&nbsp;&nbsp;&nbsp;{{
              webConfig.beiAnText
            }}</span>
        </div>
      </div>
    </div>

    <template>
      <el-backtop :bottom="50"></el-backtop>
    </template>
  </div>
</template>

<script>
import elDragDialog from "@/directive/el-drag-dialog";
import {H5_URL} from "@/model/ConfigModel";
import {isMobile} from "@/utils/common";
import {OfficialWebExperimentModel} from "@/model/erp/OfficialWebExperimentModel";

export default {
  name: "web_cqcsglzyxy",
  directives: {
    elDragDialog
  },
  data() {
    return {
      window: window,
      navIndex: "1",
      webConfig: {
        "logoUrl": "http://resouce.cdzyhd.com/838d85db-07bb-446a-9f98-ae3e05dae39f.png",
        "indexBannerUrl": "http://116.63.187.43:7035/files/20220720/216212798869344256.jpeg",
        "schoolName": "重庆城市管理职业学院",
        "systemName": "思政课虚拟仿真体验教学中心",
        "colleageName": "重庆城市管理职业学院马克思主义学院",
        "focusList": [
          "http://resouce.cdzyhd.com/07366e1c-62db-4cab-b81c-b93457ee1ba9.png",
          "http://resouce.cdzyhd.com/cfda83b0-9459-453c-9312-36c2f5e622c3.png",
          "http://resouce.cdzyhd.com/f467b552-a7af-4464-a041-1467e456f7ac.png",
          "http://resouce.cdzyhd.com/9bea170a-4d6a-49a5-9352-39e1ba1904ff.png",
          "http://resouce.cdzyhd.com/709cc750-6d16-4d40-9489-6dfbdd528c51.png"
        ],
        "webName": "重庆城市管理职业学院思政课虚拟仿真体验教学中心",
        beiAnText: "蜀ICP备19034784号-1"
      },
      introduceConfig: {
        "tabs": [{
          "name": "中心简介",
          "showNav": true,
          "text": "",
          "id": "1661264029372"
        }, {
          "name": "教学团队",
          "showNav": true,
          "text": "",
          "id": "1661264039431"
        }], "name": "中心介绍"
      },
      experiments: {
        list:[],
        list_2: [
          {
            "id": 1666668501569,
            "name": "伟大建党精神",
            "subName": "讲述一百年前，中国共产党的先驱们创建",
            "subName_h5": "讲述一百年前，中国共产党的先驱们创建了中国共产党，形成了坚",
            "description": "讲述一百年前，中国共产党的先驱们创建了中国共产党，形成了坚持真理、坚守理想，践行初心、担当使命，不怕牺牲、英勇斗争，对党",
            "experimentDescription": "讲述一百年前，中国共产党的先驱们创建了中国共产党，形成了坚持真理、坚守理想，践行初心、担当使命，不怕牺牲、英勇斗争，对党忠诚、不负人民的伟大建党精神；讲述中国共产党弘扬伟大建党精神，在长期奋斗中构建起中国共产党人的精神谱系，锤炼出鲜明的政治品格的故事。\n通过交互式学习和考核，让学员在伟大建党精神形成的历史背景与过程中学习理解伟大建党精神的定义、内涵及未来影响。",
            "product_bg": "http://resouce.cdzyhd.com/1aba90d0-a339-422d-8f8f-c432b7cd9ae8.jpg",
            "product_icon": "http://resouce.cdzyhd.com/adcc12a1-5c55-4b46-8010-7c205e270770.jpg",
            "product_info_bg": "http://resouce.cdzyhd.com/31266b69-3efc-486a-99f0-eba3321a485f.jpg",
            "product_info_img": "http://resouce.cdzyhd.com/195eb4ad-4a74-41b2-85be-ab5443359493.jpg",
            "product_info_img_1": "http://resouce.cdzyhd.com/eb06b629-4278-401e-8c6b-0cb378f5abf0.jpg",
            "product_info_img_2": "http://resouce.cdzyhd.com/008caab0-ff64-4b2e-af8f-14e3af8e19aa.jpg",
            "product_info_img_3": "http://resouce.cdzyhd.com/971e9c05-fba4-4405-a088-ce9ebf81a499.jpg",
            "product_info_img_4": "http://resouce.cdzyhd.com/c2d81b01-091b-479c-84d9-73e2fb695405.jpg"
          }, {
            "id": 1666668512034,
            "name": "苏区精神",
            "subName": "讲述在革命根据地的创建和发展中，在建",
            "subName_h5": "讲述在革命根据地的创建和发展中，在建立红色政权、探索革命道",
            "description": "讲述在革命根据地的创建和发展中，在建立红色政权、探索革命道路的实践中，无数革命先辈用鲜血和生命铸就了以坚定信念、求真务实",
            "experimentDescription": "讲述在革命根据地的创建和发展中，在建立红色政权、探索革命道路的实践中，无数革命先辈用鲜血和生命铸就了以“坚定信念、求真务实、一心为民、清正廉洁、艰苦奋斗、争创一流、无私奉献”为主要内涵的苏区精神的故事。\n通过交互式学习和考核，让学员在苏区精神形成的历史背景与过程中学习理解苏区精神的定义、内涵及未来影响。",
            "product_bg": "http://resouce.cdzyhd.com/38f3e422-2da2-4458-96a4-328b2ebe66ed.jpg",
            "product_icon": "http://resouce.cdzyhd.com/1843cac5-8e4c-4460-86e4-2095b079b82c.jpg",
            "product_info_bg": "http://resouce.cdzyhd.com/ba90ebb7-7575-43e0-bd72-ffc110cb3d8d.jpg",
            "product_info_img": "http://resouce.cdzyhd.com/1d696d34-2808-4f25-be5a-b2edeb73f008.jpg",
            "product_info_img_1": "http://resouce.cdzyhd.com/051386ca-0103-4989-997c-334c4cd37818.jpg",
            "product_info_img_2": "http://resouce.cdzyhd.com/d68f5cc4-8091-4e22-a3e5-cc434e6b39c7.jpg",
            "product_info_img_3": "http://resouce.cdzyhd.com/f4da34eb-cce1-42d7-8b82-784184a418db.jpg",
            "product_info_img_4": "http://resouce.cdzyhd.com/0d2a0cc9-595c-4d97-89d0-00621123ee10.jpg"
          }, {
            "id": 1666668526771,
            "name": "西柏坡精神",
            "subName": "讲述70余年前，党中央和毛泽东同志在",
            "subName_h5": "讲述70余年前，党中央和毛泽东同志在西柏坡领导了轰轰烈烈的",
            "description": "讲述70余年前，党中央和毛泽东同志在西柏坡领导轰轰烈烈的土地改革运动，指挥彪炳史册的辽沈、淮海、平津三大战役，召开具有伟",
            "experimentDescription": "讲述70余年前，党中央和毛泽东同志在西柏坡领导轰轰烈烈的土地改革运动，指挥彪炳史册的辽沈、淮海、平津三大战役，召开具有伟大历史意义的党的七届二中全会，孕育形成了以“两个务必”为核心的西柏坡精神的故事。\n通过交互式学习和考核，让学员在西柏坡精神形成的历史背景与过程中学习理解西柏坡精神的定义、内涵及未来影响。",
            "product_bg": "http://resouce.cdzyhd.com/cb7b6860-1eb1-4d13-83e2-c247607dc186.jpg",
            "product_icon": "http://resouce.cdzyhd.com/a5414a52-9cf0-40a2-9b7d-c6c77cdbbcfe.jpg",
            "product_info_bg": "http://resouce.cdzyhd.com/f724b7ce-fa2c-4a3f-a0cd-8ff4d49fd6d9.jpg",
            "product_info_img": "http://resouce.cdzyhd.com/b9ac0fa3-e61c-4718-aff2-3f3dc4620238.jpg",
            "product_info_img_1": "http://resouce.cdzyhd.com/de8e62d5-45d9-4741-8257-fa645f4839e8.jpg",
            "product_info_img_2": "http://resouce.cdzyhd.com/5727c8b6-ea6b-41a4-9648-1619ab2b965f.jpg",
            "product_info_img_3": "http://resouce.cdzyhd.com/460cd325-ad57-4338-b741-2ebdf764a338.jpg",
            "product_info_img_4": "http://resouce.cdzyhd.com/258eed81-a3ad-4fbf-856f-c1d4764840eb.jpg"
          }, {
            "id": 1666668539421,
            "name": "南泥湾精神",
            "subName": "讲述1941年春，八路军第三五九旅开",
            "subName_h5": "讲述1941年春，八路军第三五九旅开进南泥湾实行军垦屯田。",
            "description": "讲述1941年春，八路军第三五九旅开进南泥湾实行军垦屯田，在缺衣少食、生存条件非常艰难的情况下，他们发扬自力更生、艰苦奋",
            "experimentDescription": "讲述1941年春，八路军第三五九旅开进南泥湾实行军垦屯田，在缺衣少食、生存条件非常艰难的情况下，他们发扬自力更生、艰苦奋斗的精神，把昔日荆棘遍野、荒无人烟的“烂泥湾”变成“到处是庄稼，遍地是牛羊”的“陕北的好江南”，树立起“自己动手、丰衣足食”的光辉旗帜，最终凝结成以自力更生、艰苦奋斗为主要内涵的南泥湾精神的故事。\n通过交互式学习和考核，让学员在南泥湾精神形成的历史背景与过程中学习理解南泥湾精神的定义、内涵及未来影响。",
            "product_bg": "http://resouce.cdzyhd.com/3d605ae5-061a-4d4a-b526-6bd1c8606bf0.jpg",
            "product_icon": "http://resouce.cdzyhd.com/e7acf8f2-44d5-490d-a241-fb1407b87ba6.jpg",
            "product_info_bg": "http://resouce.cdzyhd.com/ccd11877-7574-48fb-8b54-9e389161b663.jpg",
            "product_info_img": "http://resouce.cdzyhd.com/89a93cd2-152a-4d70-98c6-aab44cd9f909.jpg",
            "product_info_img_1": "http://resouce.cdzyhd.com/48b52e2d-1897-49db-8f9b-9277adca95c2.jpg",
            "product_info_img_2": "http://resouce.cdzyhd.com/1f7fbd4f-e473-4b6e-8e8d-2a3ba1c3442f.jpg",
            "product_info_img_3": "http://resouce.cdzyhd.com/68f73575-6cbb-458f-897a-0902833e5ee4.jpg",
            "product_info_img_4": "http://resouce.cdzyhd.com/228c913d-7aa0-4175-84ec-f1cf8c10bffb.jpg"
          }, {
            "id": 1666668541545,
            "name": "沂蒙精神",
            "subName": "讲述从1938年到1949年，在长达",
            "subName_h5": "讲述从1938年到1949年，在长达12年的沂蒙革命斗争实",
            "description": "讲述从1938年到1949年，在长达12年的沂蒙革命斗争实践中，各级党组织和人民军队为了人民利益出生入死、浴血奋战，沂蒙",
            "experimentDescription": "讲述从1938年到1949年，在长达12年的沂蒙革命斗争实践中，各级党组织和人民军队为了人民利益出生入死、浴血奋战，沂蒙人民在党的领导下舍生忘死、参军参战、奋勇支前，党政军民水乳交融、生死与共，共同铸就了伟大的沂蒙精神的故事。\n通过交互式学习和考核，让学员在沂蒙精神形成的历史背景与过程中学习理解沂蒙精神的定义、内涵及未来影响。",
            "product_bg": "http://resouce.cdzyhd.com/cf3cdc01-e44d-473b-95c0-2fd0c2eb594f.jpg",
            "product_icon": "http://resouce.cdzyhd.com/f8a87100-73e0-47d4-a650-39ca8285a05c.jpg",
            "product_info_bg": "http://resouce.cdzyhd.com/9c0fc7b6-ba2a-4a21-84ea-989f63c90e3f.jpg",
            "product_info_img": "http://resouce.cdzyhd.com/64cb1109-603f-44c5-8a89-8f105213bee5.jpg",
            "product_info_img_1": "http://resouce.cdzyhd.com/85d5f118-b4bb-4056-9546-9a5327e3363c.jpg",
            "product_info_img_2": "http://resouce.cdzyhd.com/1ec9751a-4f6a-4044-b3bc-37f82808e945.jpg",
            "product_info_img_3": "http://resouce.cdzyhd.com/55253a09-a918-46b0-8709-2c6374fbedd7.jpg",
            "product_info_img_4": "http://resouce.cdzyhd.com/ad83bd74-0bec-4d0d-b15f-60ce3fac66e6.jpg"
          }, {
            "id": 1666668552526,
            "name": "井冈山精神",
            "subName": "讲述1927年10月，毛泽东同志率领",
            "subName_h5": "讲述1927年10月，毛泽东同志率领秋收起义部队走上井冈山",
            "description": "讲述1927年10月，毛泽东同志率领秋收起义部队走上井冈山，创建了第一个农村革命根据地，开辟了“农村包围城市、武装夺取政",
            "experimentDescription": "讲述1927年10月，毛泽东同志率领秋收起义部队走上井冈山，创建了第一个农村革命根据地，开辟了“农村包围城市、武装夺取政权”的中国革命道路，孕育了“坚定信念、艰苦奋斗，实事求是、敢闯新路，依靠群众、勇于胜利”的井冈山精神的故事。\n通过交互式学习和考核，让学员在井冈山精神形成的历史背景与过程中学习理解井冈山精神的定义、内涵及未来影响。",
            "product_bg": "http://resouce.cdzyhd.com/bbe04660-631e-4e1b-adcd-5f67da6d5004.jpg",
            "product_icon": "http://resouce.cdzyhd.com/251e7f84-0515-450a-b425-dd8f53e46297.jpg",
            "product_info_bg": "http://resouce.cdzyhd.com/b52ca4c2-ada7-4652-8a06-971dfcbad795.jpg",
            "product_info_img": "http://resouce.cdzyhd.com/0bce87af-c2ba-45e2-8478-4f5bcbe4856f.jpg",
            "product_info_img_1": "http://resouce.cdzyhd.com/5c9e4a0c-1161-4d86-8482-123e4edf7c7e.jpg",
            "product_info_img_2": "http://resouce.cdzyhd.com/37ac7cd4-a94f-4b30-8db1-74d7b36bd4f4.jpg",
            "product_info_img_3": "http://resouce.cdzyhd.com/f8b58b08-debb-4832-88ec-73b0071ab823.jpg",
            "product_info_img_4": "http://resouce.cdzyhd.com/2f574f22-a327-467e-842b-30634b383370.jpg"
          }, {
            "id": 1666668563254,
            "name": "伟大长征精神",
            "subName": "讲述长征期间经过党和人民共同的艰苦探",
            "subName_h5": "讲述长征期间经过党和人民共同的艰苦探索，把全国人民和中华民",
            "description": "讲述长征期间经过党和人民共同的艰苦探索，最终凝结了以“把全国人民和中华民族的根本利益看得高于一切，坚定革命的理想和信念，",
            "experimentDescription": "讲述长征期间经过党和人民共同的艰苦探索，最终凝结了以“把全国人民和中华民族的根本利益看得高于一切，坚定革命的理想和信念，坚信正义事业必然胜利的精神；为了救国救民，不怕任何艰难险阻，不惜付出一切牺牲的精神；坚持独立自主、实事求是，一切从实际出发的精神；顾全大局、严守纪律、紧密团结的精神；紧紧依靠人民群众，同人民群众生死相依、患难与共、艰苦奋斗的精神”为主要内涵的伟大长征精神的故事。\n通过交互式学习和考核，让学员在伟大长征精神形成的历史背景与过程中学习理解伟大长征精神的定义、内涵及未来影响。",
            "product_bg": "http://resouce.cdzyhd.com/6574454a-465e-4445-82b4-bb296dbdd2a5.jpg",
            "product_icon": "http://resouce.cdzyhd.com/b21fa29c-df5e-4224-8494-03619e59023f.jpg",
            "product_info_bg": "http://resouce.cdzyhd.com/4245807c-36c9-4e45-96dd-d96a3e03a0e0.jpg",
            "product_info_img": "http://resouce.cdzyhd.com/5c5725e1-a0c2-48e1-8b1e-5242dbb87538.jpg",
            "product_info_img_1": "http://resouce.cdzyhd.com/324a7d85-ff53-4e6d-baad-b80efcf11586.jpg",
            "product_info_img_2": "http://resouce.cdzyhd.com/cc0464e4-021e-44ef-86b3-4449a388fdb8.jpg",
            "product_info_img_3": "http://resouce.cdzyhd.com/20e6d396-62c8-4031-97ea-7e5ac5b5c0d6.jpg",
            "product_info_img_4": "http://resouce.cdzyhd.com/b59d138a-911f-466c-831d-ed17f8a33e87.jpg"
          }, {
            "id": 1666668561049,
            "name": "伟大抗战精神",
            "subName": "讲述在抗日战争时期，在民族危亡的历史",
            "subName_h5": "讲述在抗日战争时期，在民族危亡的历史关头，中国共产党以卓越",
            "description": "讲述在抗日战争时期，在民族危亡的历史关头，中国共产党以卓越的政治领导力和正确的战略策略，指引了中国抗战的前进方向，坚定不",
            "experimentDescription": "讲述在抗日战争时期，在民族危亡的历史关头，中国共产党以卓越的政治领导力和正确的战略策略，指引了中国抗战的前进方向，坚定不移推动全民族坚持抗战、团结、进步，反对妥协、分裂、倒退；中国共产党高举抗日民族统一战线的旗帜，坚决维护、巩固、发展统一战线，坚持独立自主、团结抗战，在中国人民抗日战争的壮阔进程中形成的伟大抗战精神的故事。\n通过交互式学习和考核，让学员在伟大抗战精神形成的历史背景与过程中学习理解伟大抗战精神的定义、内涵及未来影响。",
            "product_bg": "http://resouce.cdzyhd.com/4e8e3d6c-ca60-4e99-862a-c3f2bc7f396f.jpg",
            "product_icon": "http://resouce.cdzyhd.com/ee13e20a-3d18-4eca-9fac-6b9ec593ec88.jpg",
            "product_info_bg": "http://resouce.cdzyhd.com/4feddad6-72ec-4792-9607-13506c253efc.jpg",
            "product_info_img": "http://resouce.cdzyhd.com/d1874367-e49f-432a-9182-a507b719e4b4.jpg",
            "product_info_img_1": "http://resouce.cdzyhd.com/84f930de-b4db-4b98-82cc-d5ae03df7ad1.jpg",
            "product_info_img_2": "http://resouce.cdzyhd.com/53a3007a-9f06-43cb-8689-7b00d2f98af0.jpg",
            "product_info_img_3": "http://resouce.cdzyhd.com/4237167b-d85c-409e-810b-12b168ed395a.jpg",
            "product_info_img_4": "http://resouce.cdzyhd.com/9a456639-9d4e-4e6b-bb7a-4ebbc9b5e3e2.jpg"
          }, {
            "id": 1666668571403,
            "name": "\"两弹一星\"精神",
            "subName": "讲述在一穷二白的基础上起步，新中国仅",
            "subName_h5": "讲述在一穷二白的基础上起步，新中国仅用了10年左右的时间就",
            "description": "讲述在一穷二白的基础上起步，新中国仅用了10年左右的时间就创造了原子弹爆炸、导弹飞行和人造卫星上天的奇迹，取得了“两弹一",
            "experimentDescription": "讲述在一穷二白的基础上起步，新中国仅用了10年左右的时间就创造了原子弹爆炸、导弹飞行和人造卫星上天的奇迹，取得了“两弹一星”事业的辉煌成就；钱学森、钱三强、邓稼先等一大批科研工作者把汗水和热血洒在茫茫戈壁，孕育出了“热爱祖国、无私奉献，自力更生、艰苦奋斗，大力协同、勇于登攀”的“两弹一星”精神的故事。\n通过交互式学习和考核，让学员在“两弹一星”精神形成的历史背景与过程中学习理解“两弹一星”精神的定义、内涵及未来影响",
            "product_bg": "http://resouce.cdzyhd.com/eae31fe6-8359-4e16-b48f-fe54de9e44ef.jpg",
            "product_icon": "http://resouce.cdzyhd.com/b174e26f-a1d2-489c-9508-1b7b851b220d.jpg",
            "product_info_bg": "http://resouce.cdzyhd.com/1ec554e5-9062-4919-8103-67f4f8dfd996.jpg",
            "product_info_img": "http://resouce.cdzyhd.com/7be1bd8c-8e0a-49a7-9e99-0c3f7f35ceee.jpg",
            "product_info_img_1": "http://resouce.cdzyhd.com/e3c500d7-1d4e-4e38-b349-1bb145dcc203.jpg",
            "product_info_img_2": "http://resouce.cdzyhd.com/a41bcf2a-92ad-432c-8cae-136ef951c45e.jpg",
            "product_info_img_3": "http://resouce.cdzyhd.com/5e99e048-c124-4499-894b-d96f3812e2bc.jpg",
            "product_info_img_4": "http://resouce.cdzyhd.com/7e744c48-a01b-49d0-891d-b28164c1624b.jpg"
          }, {
            "id": 1666668576144,
            "name": "大庆精神",
            "subName": "讲述在大庆油田的开发建设之初，石油工",
            "subName_h5": "讲述在大庆油田的开发建设之初，石油工人们在极端困难条件下艰",
            "description": "讲述在大庆油田的开发建设之初，石油工人们在极端困难条件下艰苦卓绝地开展的石油大会战；1959年9月26日，位于松嫩平原上",
            "experimentDescription": "讲述在大庆油田的开发建设之初，石油工人们在极端困难条件下艰苦卓绝地开展的石油大会战；1959年9月26日，位于松嫩平原上的松基3井，喷射出一股黑色油流，我国勘探发现了大庆油田，一场波澜壮阔的石油大会战由此打响，“早日把中国石油落后的帽子甩到太平洋里去”“宁肯少活二十年，拼命也要拿下大油田”，这场4万多人参与的石油大会战，铸就了以“爱国、创业、求实、奉献”为精神内涵的故事。\n通过交互式学习和考核，让学员在大庆精神形成的历史背景与过程中学习理解大庆精神的定义、内涵及未来影响。",
            "product_bg": "http://resouce.cdzyhd.com/653f5c98-9557-46a1-a2a1-6ddfeef4ebb0.jpg",
            "product_icon": "http://resouce.cdzyhd.com/1ac13dd5-40a7-4619-82fe-2e7b4aeb353e.jpg",
            "product_info_bg": "http://resouce.cdzyhd.com/bbc5d70e-6951-4742-b334-36c1087c3398.jpg",
            "product_info_img": "http://resouce.cdzyhd.com/3e1f89b8-97d8-4b6d-8a4c-4ff8e957356e.jpg",
            "product_info_img_1": "http://resouce.cdzyhd.com/4d83d586-30d3-4d69-83d5-62253a5e29db.jpg",
            "product_info_img_2": "http://resouce.cdzyhd.com/61ff9983-43ab-433f-8454-838360d337a2.jpg",
            "product_info_img_3": "http://resouce.cdzyhd.com/9ef45023-50d1-4111-b375-83e3728e4079.jpg",
            "product_info_img_4": "http://resouce.cdzyhd.com/42d2a8ef-4a18-4693-8a2d-7a82df81cc70.jpg"
          }, {
            "id": 1666668588955,
            "name": "塞罕坝精神",
            "subName": "讲述河北省塞罕坝林场三代人的建设者们",
            "subName_h5": "讲述河北省塞罕坝林场三代人的建设者们，听从党的召唤，用青春",
            "description": "讲述河北省塞罕坝林场三代人的建设者们听从党的召唤，用青春与奋斗，创造了荒原变林海的“人间奇迹”，以实际行动诠释了“绿水青",
            "experimentDescription": "讲述河北省塞罕坝林场三代人的建设者们听从党的召唤，用青春与奋斗，创造了荒原变林海的“人间奇迹”，以实际行动诠释了“绿水青山就是金山银山”的理念，铸就了以“牢记使命、艰苦创业、绿色发展”为精神内涵的故事。\n通过交互式学习和考核，让学员在塞罕坝精神形成的历史背景与过程中学习理解塞罕坝精神的定义、内涵及未来影响。",
            "product_bg": "http://resouce.cdzyhd.com/31f5dfa9-8418-4d6a-9f5f-ec200fbdb4bd.jpg",
            "product_icon": "http://resouce.cdzyhd.com/cb9139fa-d739-4b64-8aed-ac0c0a11039f.jpg",
            "product_info_img": "http://resouce.cdzyhd.com/00572a1a-6aa6-41fb-930d-c4fb339290bc.jpg",
            "product_info_bg": "http://resouce.cdzyhd.com/7cfdd561-296b-47c2-81fc-eddd5a957cd6.jpg",
            "product_info_img_1": "http://resouce.cdzyhd.com/76c94589-2bde-4c3d-97b6-a6ee9f5d12cf.jpg",
            "product_info_img_2": "http://resouce.cdzyhd.com/005241fb-6fb1-4e0a-88a9-d54a1dbec3cb.jpg",
            "product_info_img_3": "http://resouce.cdzyhd.com/a9f0213c-7750-4c01-9ef5-dd84be5b1490.jpg",
            "product_info_img_4": "http://resouce.cdzyhd.com/1e07d2eb-22b8-4054-83af-8147daeb29a9.jpg"
          }, {
            "id": 1666668595255,
            "name": "抗美援朝精神",
            "subName": "讲述在波澜壮阔的抗美援朝战争中，英雄",
            "subName_h5": "讲述在波澜壮阔的抗美援朝战争中，英雄的中国人民志愿军始终发",
            "description": "讲述在波澜壮阔的抗美援朝战争中，英雄的中国人民志愿军始终发扬祖国和人民利益高于一切、为了祖国和民族的尊严而奋不顾身的爱国",
            "experimentDescription": "讲述在波澜壮阔的抗美援朝战争中，英雄的中国人民志愿军始终发扬祖国和人民利益高于一切、为了祖国和民族的尊严而奋不顾身的爱国主义精神，英勇顽强、舍生忘死的革命英雄主义精神，不畏艰难困苦、始终保持高昂士气的革命乐观主义精神，为完成祖国和人民赋予的使命、慷慨奉献自己一切的革命忠诚精神，为了人类和平与正义事业而奋斗的国际主义精神，锻造了伟大抗美援朝精神的故事。\n通过交互式学习和考核，让学员在抗美援朝精神形成的历史背景与过程中学习理解抗美援朝精神的定义、内涵及未来影响。",
            "product_bg": "http://resouce.cdzyhd.com/e13a9029-18ed-42e2-ae7e-fc067850c157.jpg",
            "product_icon": "http://resouce.cdzyhd.com/e95d0d12-85f3-435b-a4d4-e2cc2e95693f.jpg",
            "product_info_bg": "http://resouce.cdzyhd.com/74aae466-e8fd-44f3-9366-ace260d1e20f.jpg",
            "product_info_img": "http://resouce.cdzyhd.com/fe918586-f13e-4d51-a2c8-bfb4a8c5ad76.jpg",
            "product_info_img_1": "http://resouce.cdzyhd.com/68299fbf-8253-4020-a811-54c0c87e3e6b.jpg",
            "product_info_img_2": "http://resouce.cdzyhd.com/5411f299-b70b-4027-9548-2af23f856d57.jpg",
            "product_info_img_3": "http://resouce.cdzyhd.com/7692cfae-0214-4b18-8846-2f0e368b8fc3.jpg",
            "product_info_img_4": "http://resouce.cdzyhd.com/4b6b91d1-c631-4dc3-895d-ea5a6ad5b283.jpg"
          }, {
            "id": 1666668600599,
            "name": "焦裕禄精神",
            "subName": "讲述从靠一辆自行车和一双铁脚板走访和",
            "subName_h5": "讲述从靠一辆自行车和一双铁脚板走访和蹲点调研120多个生产",
            "description": "讲述从靠一辆自行车和一双铁脚板走访和蹲点调研120多个生产大队，到用手甚至硬物顶住剧痛的肝部坚持下乡、忘我工作的焦裕禄同",
            "experimentDescription": "讲述从靠一辆自行车和一双铁脚板走访和蹲点调研120多个生产大队，到用手甚至硬物顶住剧痛的肝部坚持下乡、忘我工作的焦裕禄同志用自己的实际行动，塑造了一名优秀共产党员和优秀县委书记的光辉形象，铸就了以“亲民爱民、艰苦奋斗、科学求实、迎难而上、无私奉献”为精神内涵的故事。\n通过交互式学习和考核，让学员在焦裕禄精神形成的历史背景与过程中学习理解焦裕禄精神的定义、内涵及未来影响。",
            "product_bg": "http://resouce.cdzyhd.com/ef3a893b-aaa0-463b-80f9-33bf48b84896.jpg",
            "product_icon": "http://resouce.cdzyhd.com/8d498b16-204f-455e-91b7-cfda83cadca3.jpg",
            "product_info_bg": "http://resouce.cdzyhd.com/921b885e-540e-40ae-bcf6-7ce2b834e7c0.jpg",
            "product_info_img": "http://resouce.cdzyhd.com/c6af6526-d48d-4eaf-8284-6bd0b5259193.jpg",
            "product_info_img_1": "http://resouce.cdzyhd.com/0f7a3f99-c199-4aac-9e21-7c1ca111e9e6.jpg",
            "product_info_img_2": "http://resouce.cdzyhd.com/5f6eb2a7-6653-4152-8208-d37c6fbe863d.jpg",
            "product_info_img_3": "http://resouce.cdzyhd.com/08ae6904-537a-482c-889b-889eca07a982.jpg",
            "product_info_img_4": "http://resouce.cdzyhd.com/fd6a5f81-adfe-4c03-9308-bb102ff99904.jpg"
          }, {
            "id": 1666668613657,
            "name": "雷锋精神",
            "subName": "讲述雷锋时刻执行着毛主席提倡的“为人",
            "subName_h5": "讲述雷锋时刻执行着毛主席提倡的“为人民服务”，时刻帮助着人",
            "description": "讲述雷锋时刻执行着毛主席提倡的“为人民服务”，时刻帮助着人民，孕育形成了以“热爱党、热爱祖国、热爱社会主义的崇高理想和坚",
            "experimentDescription": "讲述雷锋时刻执行着毛主席提倡的“为人民服务”，时刻帮助着人民，孕育形成了以“热爱党、热爱祖国、热爱社会主义的崇高理想和坚定信念；服务人民、助人为乐的奉献精神；干一行爱一行、专一行精一行的敬业精神；锐意进取、自强不息的创新精神；艰苦奋斗、勤俭节约的创业精神”为精神内涵的故事。\n通过交互式学习和考核，让学生在雷锋精神形成的历史背景与过程中学习理解雷锋精神的定义、内涵及未来影响。",
            "product_bg": "http://resouce.cdzyhd.com/91a5287a-6183-4c0f-8c49-bb31aefa5907.jpg",
            "product_icon": "http://resouce.cdzyhd.com/c9f338dd-6596-428e-84c2-0812df4c9e54.jpg",
            "product_info_bg": "http://resouce.cdzyhd.com/4370e07f-5032-4da3-8647-aea89ed69886.jpg",
            "product_info_img": "http://resouce.cdzyhd.com/7f011805-0e1b-44ef-87c0-73b8638323f5.jpg",
            "product_info_img_1": "http://resouce.cdzyhd.com/61546a30-0437-408c-8bed-04136bc3dbfd.jpg",
            "product_info_img_2": "http://resouce.cdzyhd.com/ad149c87-a6be-4956-9072-88ad0a4dc4e3.jpg",
            "product_info_img_3": "http://resouce.cdzyhd.com/da3fbf3e-4d96-4406-b5e9-37c82e2439ea.jpg",
            "product_info_img_4": "http://resouce.cdzyhd.com/f26497ad-b5a9-4a82-9fdc-374f4c5e4370.jpg"
          }, {
            "id": 1666668617760,
            "name": "红旗渠精神",
            "subName": "讲述从1960年2月到1969年7月",
            "subName_h5": "讲述从1960年2月到1969年7月，先后有30多万人次的",
            "description": "讲述从1960年2月到1969年7月，先后有30多万人次的林县儿女自带工具，自备口粮，风餐露宿，在太行山中苦干9年多，削",
            "experimentDescription": "讲述从1960年2月到1969年7月，先后有30多万人次的林县儿女自带工具，自备口粮，风餐露宿，在太行山中苦干9年多，削平1250座山头，凿通211个隧洞，架设152座渡槽，建成全长1500公里的红旗渠，结束了林县“十年九旱、水贵如油”的历史；在中国共产党领导下，林县人民自力更生、艰苦创业、团结协作、无私奉献，靠着“一锤、一钎、一双手”，创造出太行山上的人间奇迹，培育了伟大的红旗渠精神的故事。\n通过交互式学习和考核，让学员在红旗渠精神形成的历史背景与过程中学习理解红旗渠精神的定义、内涵及未来影响。",
            "product_bg": "http://resouce.cdzyhd.com/d01ec993-5c94-42fa-89d6-d2f10501c9bd.jpg",
            "product_icon": "http://resouce.cdzyhd.com/aac9916d-5ac9-4ed5-86ad-445a3256c3c3.jpg",
            "product_info_bg": "http://resouce.cdzyhd.com/fa389b5c-3951-4579-93ac-8fd7ef57037b.jpg",
            "product_info_img": "http://resouce.cdzyhd.com/ae871631-4ebb-4fe6-936e-a5f5df2ba10d.jpg",
            "product_info_img_1": "http://resouce.cdzyhd.com/7622d482-3091-4f48-8182-5c4b3b3a2247.jpg",
            "product_info_img_2": "http://resouce.cdzyhd.com/8a7efdd5-66d8-4680-887e-8c5c372fdef9.jpg",
            "product_info_img_3": "http://resouce.cdzyhd.com/7b58dad9-dc71-437b-a5cf-e10c2d3764b1.jpg",
            "product_info_img_4": "http://resouce.cdzyhd.com/0254aa0a-e59a-4243-8b12-a185d004d8c2.jpg"
          }, {
            "id": 1666668626825,
            "name": "特区精神",
            "subName": "讲述深圳经济特区在改革发展实践中形成",
            "subName_h5": "讲述深圳经济特区在改革发展实践中形成的以“敢闯、敢冒、敢试",
            "description": "讲述深圳经济特区在改革发展实践中形成的以“敢闯、敢冒、敢试、敢为天下先的改革精神，是奋发有为、只争朝夕的创业精神”为精神",
            "experimentDescription": "讲述深圳经济特区在改革发展实践中形成的以“敢闯、敢冒、敢试、敢为天下先的改革精神，是奋发有为、只争朝夕的创业精神”为精神内涵的故事。\n通过交互式学习和考核，让学员在特区精神形成的历史背景与过程中学习理解特区精神的定义、内涵及未来影响。",
            "product_bg": "http://resouce.cdzyhd.com/e4e97ae2-ea72-436a-ac73-8a6ba4d28107.jpg",
            "product_icon": "http://resouce.cdzyhd.com/28ad768c-a3f2-4c49-8017-6d93ad660aec.jpg",
            "product_info_bg": "http://resouce.cdzyhd.com/fc4c9c27-4b02-4587-8b3a-c9e84a638ce3.jpg",
            "product_info_img": "http://resouce.cdzyhd.com/b120cfbf-dbf0-451a-8ac9-0f71a030a9f2.jpg",
            "product_info_img_1": "http://resouce.cdzyhd.com/0eb8912b-34f5-4c6f-893b-42751ce16a4b.jpg",
            "product_info_img_2": "http://resouce.cdzyhd.com/f9318752-d133-4455-8a6e-afe22237115c.jpg",
            "product_info_img_3": "http://resouce.cdzyhd.com/ed30f99e-311d-499d-8d9f-49e9abdd277a.jpg",
            "product_info_img_4": "http://resouce.cdzyhd.com/95e85833-ef70-41c8-8b78-e77db5e29238.jpg"
          }, {
            "id": 1666668631013,
            "name": "载人航天精神",
            "subName": "讲述上世纪90年代初，面对世界科技进",
            "subName_h5": "讲述上世纪90年代初，面对世界科技进步突飞猛进、综合国力竞",
            "description": "讲述上世纪90年代初，面对世界科技进步突飞猛进、综合国力竞争日趋激烈的新形势，党中央高瞻远瞩、审时度势，对我国尖端科技事",
            "experimentDescription": "讲述上世纪90年代初，面对世界科技进步突飞猛进、综合国力竞争日趋激烈的新形势，党中央高瞻远瞩、审时度势，对我国尖端科技事业的发展进行了全面部署，作出实施载人航天工程的重大战略决策；几十年来，航天人发扬“特别能吃苦、特别能战斗、特别能攻关、特别能奉献”的载人航天精神，取得了连战连捷的辉煌战绩，使我国空间技术发展跨入了国际先进行列的故事。\n通过交互式学习和考核，让学员在载人航天精神形成的历史背景与过程中学习理解载人航天精神的定义、内涵及未来影响。",
            "product_bg": "http://resouce.cdzyhd.com/4c2baec6-0a52-4e2f-861d-4dba40ddc474.jpg",
            "product_icon": "http://resouce.cdzyhd.com/ff6a6b53-18de-4bbf-9b4d-00f00cd7bb2f.jpg",
            "product_info_bg": "http://resouce.cdzyhd.com/ea24762b-29ce-4b28-83c8-fc69e81778e8.jpg",
            "product_info_img": "http://resouce.cdzyhd.com/d58602ea-c5a9-4f3c-b444-e3bc1e2063af.jpg",
            "product_info_img_1": "http://resouce.cdzyhd.com/3cb04f91-3a0d-409f-9f03-c5e0c015283a.jpg",
            "product_info_img_2": "http://resouce.cdzyhd.com/c0dcddbe-8773-4d4a-8889-2dcc1b9f1008.jpg",
            "product_info_img_3": "http://resouce.cdzyhd.com/f7666bf7-a49c-46b5-b6bb-a4c4bdee2492.jpg",
            "product_info_img_4": "http://resouce.cdzyhd.com/b79634b2-2d8c-41d6-a244-79ae0be6f1a9.jpg"
          },
          {
            "id": 1666668640306,
            "name": "伟大抗疫精神",
            "subName": "讲述我国遭遇传播速度最快、传染范围最",
            "subName_h5": "讲述我国遭遇传播速度最快、传染范围最广、防控难度最大的新冠",
            "description": "讲述我国遭遇传播速度最快、传染范围最广、防控难度最大的新冠肺炎疫情重大突发公共卫生事件后，面对来势汹汹的严峻疫情，以习近",
            "experimentDescription": "讲述我国遭遇传播速度最快、传染范围最广、防控难度最大的新冠肺炎疫情重大突发公共卫生事件后，面对来势汹汹的严峻疫情，以习近平同志为核心的党中央团结带领全党全军全国人民，坚持人民至上、生命至上，以坚定果敢的勇气和坚韧不拔的决心，迅速打响疫情防控的人民战争、总体战、阻击战，夺取全国抗疫斗争重大战略成果，创造了人类同疾病斗争史上的奇迹，铸就了以“生命至上、举国同心、舍生忘死、尊重科学、命运与共”的精神内涵的故事。\n通过交互式学习和考核，让学员在伟大抗疫精神形成的历史背景与过程中学习理解伟大抗疫精神的定义、内涵及未来影响。",
            "product_bg": "http://resouce.cdzyhd.com/520c7eef-0e8e-4e8c-91d7-6ff3cf4b6a9a.jpg",
            "product_icon": "http://resouce.cdzyhd.com/cec4fcc3-8ecb-4a85-b8cd-ba33d126a06b.jpg",
            "product_info_bg": "http://resouce.cdzyhd.com/1cceba81-cb60-4327-99b8-300509ba2b59.jpg",
            "product_info_img": "http://resouce.cdzyhd.com/f28a6b26-e127-4149-8990-df97ce3dd296.jpg",
            "product_info_img_1": "http://resouce.cdzyhd.com/91800e92-d4f4-4804-83f2-eabe35080930.jpg",
            "product_info_img_2": "http://resouce.cdzyhd.com/42b00418-b943-4b82-951d-638009f9f5b8.jpg",
            "product_info_img_3": "http://resouce.cdzyhd.com/6e5b46fa-732f-46b1-b2fb-11e96f95ecd6.jpg",
            "product_info_img_4": "http://resouce.cdzyhd.com/18c22bc9-7090-439f-9cd1-ea3e31dac8ce.jpg"
          },


          {
            "id": 1,
            sample: true,
            "name": "北大荒精神",
            "subName": "讲述北大荒人的政治觉悟、精神境界",
            "subName_h5": "讲述北大荒人的政治觉悟、精神境界、道德情操和意志品质，",
            "description": "讲述北大荒人的政治觉悟、精神境界、道德情操和意志品质，从亘古荒原到“中华大粮仓”，几代农垦人战天斗地，锻造出“艰苦奋斗",
            "experimentDescription": "讲述北大荒人的政治觉悟、精神境界、道德情操和意志品质，从亘古荒原到“中华大粮仓”，几代农垦人战天斗地，锻造出“艰苦奋斗、勇于开拓、顾全大局、无私奉献”的北大荒精神的故事。通过交互式学习和考核，让学生在北大荒精神形成的历史背景与过程中学习理解北大荒精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/db76822b-93e4-48e4-8698-24a3fc6702d9.png",
            "product_info_bg": "http://resouce.cdzyhd.com/3ccb8a9e-8563-402d-8f2e-07497ea6798f.png",
            "product_icon": "http://resouce.cdzyhd.com/6335714d-7221-43ae-a057-c49f022602df.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "大别山精神",
            "subName": "讲述从中国共产党诞生到新中国成立，以大别山为中心的鄂豫皖三省交界地区，由中国共产党及其领导的武装力量和革命群众",
            "subName_h5": "讲述从中国共产党诞生到新中国成立，以大别山为中心的鄂豫皖三省交界地区，由中国共产党及其领导的武装力量和革命群众",
            "description": "讲述从中国共产党诞生到新中国成立，以大别山为中心的鄂豫皖三省交界地区，由中国共产党及其领导的武装力量和革命群众",
            "experimentDescription": "讲述从中国共产党诞生到新中国成立，以大别山为中心的鄂豫皖三省交界地区，由中国共产党及其领导的武装力量和革命群众，为了实现共产主义理想、建立新中国而形成的革命精神。孕育形成了以“坚守信念、胸怀全局、团结奋进、勇当前锋”为精神内涵的故事。通过交互式学习和考核，让学生在大别山精神形成的历史背景与过程中学习理解大别山精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/af804510-e8b2-4727-a676-125dfd5be15b.png",
            "product_info_bg": "http://resouce.cdzyhd.com/2f1ea886-7085-475d-948d-f3e2ee400210.png",
            "product_icon": "http://resouce.cdzyhd.com/2638cb96-4c9b-461b-897c-528cd2e34c99.png"
          },
          {
            "id": 3,
            sample: true,
            "name": "东北抗联精神",
            "subName": "讲述从1931年“九一八”事变爆发，到1945年9月2日日本签署投降书，在长达14年血与火、生与死的艰苦斗争中，",
            "subName_h5": "讲述从1931年“九一八”事变爆发，到1945年9月2日日本签署投降书，在长达14年血与火、生与死的艰苦斗争中，",
            "description": "讲述从1931年“九一八”事变爆发，到1945年9月2日日本签署投降书，在长达14年血与火、生与死的艰苦斗争中，",
            "experimentDescription": "讲述从1931年“九一八”事变爆发，到1945年9月2日日本签署投降书，在长达14年血与火、生与死的艰苦斗争中，中国共产党所领导的东北抗日联军，为抗日战争的胜利作出了重要贡献，用理想和信念铸就了宝贵的东北抗联精神的故事。通过交互式学习和考核，让学生在东北抗联精神形成的历史背景与过程中学习理解东北抗联精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/f4e34c5c-0d76-42d5-b81c-b4f36e261da0.png",
            "product_info_bg": "http://resouce.cdzyhd.com/dc17385a-bd7f-4d5b-9ec5-7d3b822bc8e9.png",
            "product_icon": "http://resouce.cdzyhd.com/2920629d-0303-4285-90c6-3c628945f8c3.png"
          },
          {
            "id": 4,
            sample: true,
            "name": "改革开放精神",
            "subName": "讲述中国共产党在改革开放实践、探索和发展中国特色社会主义事业这一特定的历史时期中所形成开拓创新、勇于担当",
            "subName_h5": "讲述中国共产党在改革开放实践、探索和发展中国特色社会主义事业这一特定的历史时期中所形成开拓创新、勇于担当",
            "description": "讲述中国共产党在改革开放实践、探索和发展中国特色社会主义事业这一特定的历史时期中所形成开拓创新、勇于担当",
            "experimentDescription": "讲述中国共产党在改革开放实践、探索和发展中国特色社会主义事业这一特定的历史时期中所形成开拓创新、勇于担当、开放包容、兼容并蓄的精神品格，孕育形成了“解放思想、实事求是，敢闯敢试、勇于创新，互利合作、命运与共”精神内涵的故事。通过交互式学习和考核，让学生在改革开放精神形成的历史背景与过程中学习理解改革开放精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/a907e067-4a02-46bc-8b98-8a863929a309.png",
            "product_info_bg": "http://resouce.cdzyhd.com/f495beb5-de36-4953-8a96-a0864341ce1f.png",
            "product_icon": "http://resouce.cdzyhd.com/772ab52b-5033-4f1a-ae18-ea9c33d6041b.png"
          },
          {
            "id": 5,
            sample: true,
            "name": "抗洪精神",
            "subName": "讲述1998年夏天，气候异常，暴雨频发，长江出现全流域性大水，东北嫩江、松花江也暴发特大洪水。千钧一发之际，",
            "subName_h5": "讲述1998年夏天，气候异常，暴雨频发，长江出现全流域性大水，东北嫩江、松花江也暴发特大洪水。千钧一发之际，",
            "description": "讲述1998年夏天，气候异常，暴雨频发，长江出现全流域性大水，东北嫩江、松花江也暴发特大洪水。千钧一发之际，",
            "experimentDescription": "讲述1998年夏天，气候异常，暴雨频发，长江出现全流域性大水，东北嫩江、松花江也暴发特大洪水。千钧一发之际，在党中央坚强领导下，全党、全军和全国人民紧急行动，广大党员干部群众同解放军指战员、武警官兵一起发扬“万众一心、众志成城，不怕困难、顽强拼搏，坚韧不拔、敢于胜利”的伟大抗洪精神，团结奋战，力挽狂澜，同洪水进行了惊心动魄的殊死搏斗的故事。通过交互式学习和考核，让学生在抗洪精神形成的历史背景与过程中学习理解抗洪精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/9deecfed-c43d-4515-83b8-c031d0eb0124.png",
            "product_info_bg": "http://resouce.cdzyhd.com/11be1c33-6b9c-44d9-806a-4137a9add250.png",
            "product_icon": "http://resouce.cdzyhd.com/5d0318db-310f-45a1-8e3b-6463fb8697c7.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "抗击非典精神",
            "subName": "讲述2003年，面对突如其来的非典疫情，在党中央、国务院坚强领导下，全党全国人民发扬“万众一心、众志成城，",
            "subName_h5": "讲述2003年，面对突如其来的非典疫情，在党中央、国务院坚强领导下，全党全国人民发扬“万众一心、众志成城，",
            "description": "讲述2003年，面对突如其来的非典疫情，在党中央、国务院坚强领导下，全党全国人民发扬“万众一心、众志成城，",
            "experimentDescription": "讲述2003年，面对突如其来的非典疫情，在党中央、国务院坚强领导下，全党全国人民发扬“万众一心、众志成城，团结互助、和衷共济，迎难而上、敢于胜利”精神，齐心协力夺取抗击非典和促进发展双胜利的故事。通过交互式学习和考核，让学生在抗击非典精神形成的历史背景与过程中学习理解抗击非典精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/0bb97265-39e6-4dfd-84de-19987ea9b667.png",
            "product_info_bg": "http://resouce.cdzyhd.com/73f1deac-ef4f-404d-8bfb-21e3f966141e.png",
            "product_icon": "http://resouce.cdzyhd.com/9588889d-5631-4197-9626-2cad8a5ad4fd.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "科学家精神",
            "subName": "讲述一代又一代矢志报国的科学家前赴后继、接续奋斗凝结成胸怀祖国、服务人民的爱国精神，勇攀高峰、敢为人先的创新精神",
            "subName_h5": "讲述一代又一代矢志报国的科学家前赴后继、接续奋斗凝结成胸怀祖国、服务人民的爱国精神，勇攀高峰、敢为人先的创新精神",
            "description": "讲述一代又一代矢志报国的科学家前赴后继、接续奋斗凝结成胸怀祖国、服务人民的爱国精神，勇攀高峰、敢为人先的创新精神",
            "experimentDescription": "讲述一代又一代矢志报国的科学家前赴后继、接续奋斗凝结成胸怀祖国、服务人民的爱国精神，勇攀高峰、敢为人先的创新精神，追求真理、严谨治学的求实精神，淡泊名利、潜心研究的奉献精神，集智攻关、团结协作的协同精神，甘为人梯、奖掖后学的育人精神的科学家精神的故事。通过交互式学习和考核，让学生在科学家精神形成的历史背景与过程中学习理解科学家精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/7d5d4966-7c11-4f62-9374-a74c75affacc.png",
            "product_info_bg": "http://resouce.cdzyhd.com/dd85abc4-623b-4d29-8faa-70bacbb9a8e5.png",
            "product_icon": "http://resouce.cdzyhd.com/751e764b-b799-4b93-b8a4-29a2072ce2a1.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "劳模精神",
            "subName": "讲述在工厂、医院、乡间、社区，在神州大地的每一个角落，广大劳动模范与亿万劳动者一起发扬爱岗敬业、",
            "subName_h5": "讲述在工厂、医院、乡间、社区，在神州大地的每一个角落，广大劳动模范与亿万劳动者一起发扬爱岗敬业、",
            "description": "讲述在工厂、医院、乡间、社区，在神州大地的每一个角落，广大劳动模范与亿万劳动者一起发扬爱岗敬业、",
            "experimentDescription": "讲述在工厂、医院、乡间、社区，在神州大地的每一个角落，广大劳动模范与亿万劳动者一起发扬爱岗敬业、争创一流、艰苦奋斗、勇于创新、淡泊名利、甘于奉献的劳模精神，胼手胝足、挥汗如雨地辛勤劳作，托举起一个充满活力的中国的故事。通过交互式学习和考核，让学生在劳模精神形成的历史背景与过程中学习理解劳模精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/47def250-7d9c-4180-886e-a662c925788d.png",
            "product_info_bg": "http://resouce.cdzyhd.com/968f35b3-6205-4275-8061-a7351e3cdb22.png",
            "product_icon": "http://resouce.cdzyhd.com/ecc90637-4163-4f50-b1ca-80a123cb47f5.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "老区精神",
            "subName": "讲述在战争年代，革命老区人民养育了中国共产党，提供了坚持长期斗争所需要的人力、物力和财力，",
            "subName_h5": "讲述在战争年代，革命老区人民养育了中国共产党，提供了坚持长期斗争所需要的人力、物力和财力，",
            "description": "讲述在战争年代，革命老区人民养育了中国共产党，提供了坚持长期斗争所需要的人力、物力和财力，",
            "experimentDescription": "讲述在战争年代，革命老区人民养育了中国共产党，提供了坚持长期斗争所需要的人力、物力和财力，为壮大革命力量、取得最后胜利，付出了巨大牺牲，作出了重大贡献孕育形成了以“爱党信党、坚定不移的理想信念；舍生忘死、无私奉献的博大胸怀；不屈不挠、敢于胜利的英雄气概；自强不息、艰苦奋斗的顽强斗志；求真务实、开拓创新的科学态度；鱼水情深、生死相依的光荣传统。”精神内涵的故事。通过交互式学习和考核，让学生在老区精神形成的历史背景与过程中学习理解老区精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/4c34dd50-2a25-47f8-8c66-342a0d06e487.png",
            "product_info_bg": "http://resouce.cdzyhd.com/1b454337-627e-44a3-8e4c-7bc1368c9d4d.png",
            "product_icon": "http://resouce.cdzyhd.com/3b96cbcf-e2f3-4e11-b612-2d0e088cd8a0.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "老西藏精神",
            "subName": "讲述以“特别能吃苦、特别能战斗、特别能忍耐、特别能团结、特别能奉献”为核心的“老西藏精神”诞生于",
            "subName_h5": "讲述以“特别能吃苦、特别能战斗、特别能忍耐、特别能团结、特别能奉献”为核心的“老西藏精神”诞生于",
            "description": "讲述以“特别能吃苦、特别能战斗、特别能忍耐、特别能团结、特别能奉献”为核心的“老西藏精神”诞生于",
            "experimentDescription": "讲述以“特别能吃苦、特别能战斗、特别能忍耐、特别能团结、特别能奉献”为核心的“老西藏精神”诞生于上世纪五六十年代西藏和平解放初期，是先辈们在行军打仗、建设西藏中，用生命和热血孕育出来的故事。通过交互式学习和考核，让学生在老西藏精神形成的历史背景与过程中学习理解老西藏精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/7cf13ffc-432a-476c-9352-bb6ecee8fabc.png",
            "product_info_bg": "http://resouce.cdzyhd.com/c8b1519a-7f88-4248-8eca-811269c1ca14.png",
            "product_icon": "http://resouce.cdzyhd.com/6e280db8-5379-4301-86d1-2e4126ae1c3b.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "女排精神",
            "subName": "讲述1981年，在第三届女排世界杯上，中国女排获得第一个世界冠军，之后创造了“五连冠”的辉煌成绩，",
            "subName_h5": "讲述1981年，在第三届女排世界杯上，中国女排获得第一个世界冠军，之后创造了“五连冠”的辉煌成绩，",
            "description": "讲述1981年，在第三届女排世界杯上，中国女排获得第一个世界冠军，之后创造了“五连冠”的辉煌成绩，",
            "experimentDescription": "讲述1981年，在第三届女排世界杯上，中国女排获得第一个世界冠军，之后创造了“五连冠”的辉煌成绩，成为世界排球史上第一支连续5次夺冠的队伍。40年来，中国女排在世界杯、世锦赛、奥运会三大赛事中屡创佳绩，在赛场上展现了祖国至上、团结协作、顽强拼搏、永不言败的精神面貌的故事。通过交互式学习和考核，让学生在女排精神形成的历史背景与过程中学习理解女排精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/270653c4-d0bd-4ab0-8776-080aa276cd16.png",
            "product_info_bg": "http://resouce.cdzyhd.com/f9da3266-767e-4cde-8bec-0a8dbc0bdbc5.png",
            "product_icon": "http://resouce.cdzyhd.com/8e40ea13-2cee-4e5c-bc5a-da2586a806e4.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "企业家精神",
            "subName": "讲述改革开放以来，一大批有胆识、勇创新的企业家带领企业战胜各种的困难，主动为国担当、为国分忧，顺应时代发展，",
            "subName_h5": "讲述改革开放以来，一大批有胆识、勇创新的企业家带领企业战胜各种的困难，主动为国担当、为国分忧，顺应时代发展，",
            "description": "讲述改革开放以来，一大批有胆识、勇创新的企业家带领企业战胜各种的困难，主动为国担当、为国分忧，顺应时代发展，",
            "experimentDescription": "讲述改革开放以来，一大批有胆识、勇创新的企业家带领企业战胜各种的困难，主动为国担当、为国分忧，顺应时代发展，勇于拼搏进取，为积累社会财富、创造就业岗位、促进经济社会发展、增强综合国力作出了重要贡献的故事。通过交互式学习和考核，让学生在企业家精神形成的历史背景与过程中学习理解企业家精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/86fad27b-4363-42ec-a8ff-dc130cf28180.png",
            "product_info_bg": "http://resouce.cdzyhd.com/de10910e-da52-44b6-8313-9f3ae15870d8.png",
            "product_icon": "http://resouce.cdzyhd.com/91c6ae35-89b8-4772-83cf-07c691d25dc8.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "青藏铁路精神",
            "subName": "讲述在创造线路最长、海拔最高、速度最快等多项世界第一的同时，",
            "subName_h5": "讲述在创造线路最长、海拔最高、速度最快等多项世界第一的同时，",
            "description": "讲述在创造线路最长、海拔最高、速度最快等多项世界第一的同时，青藏铁路人以惊人的毅力和勇气战胜了各种难以想象的困难，",
            "experimentDescription": "讲述在创造线路最长、海拔最高、速度最快等多项世界第一的同时，青藏铁路人以惊人的毅力和勇气战胜了各种难以想象的困难，用心血和汗水谱写了人类铁路发展史上的辉煌篇章，形成了“挑战极限、勇创一流”的青藏铁路精神，留下宝贵的精神财富的故事。通过交互式学习和考核，让学生在青藏铁路精神形成的历史背景与过程中学习理解青藏铁路精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/5e5b59f0-7acc-4862-8dd1-b1bcca0c4eba.png",
            "product_info_bg": "http://resouce.cdzyhd.com/ffdf6047-6992-4342-8533-747c6fcad797.png",
            "product_icon": "http://resouce.cdzyhd.com/9c78d925-be8d-4fd4-88c1-bee2d926826f.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "“三牛”精神",
            "subName": "讲述在中华文化里，牛是勤劳、奉献、奋进、力量的象征。人们把为民服务、无私奉献比喻为孺子牛",
            "subName_h5": "讲述在中华文化里，牛是勤劳、奉献、奋进、力量的象征。人们把为民服务、无私奉献比喻为孺子牛",
            "description": "讲述在中华文化里，牛是勤劳、奉献、奋进、力量的象征。人们把为民服务、无私奉献比喻为孺子牛",
            "experimentDescription": "讲述在中华文化里，牛是勤劳、奉献、奋进、力量的象征。人们把为民服务、无私奉献比喻为孺子牛，把创新发展、攻坚克难比喻为拓荒牛，把艰苦奋斗、吃苦耐劳比喻为老黄牛。前进道路上，我们要大力发扬孺子牛、拓荒牛、老黄牛精神，以不怕苦、能吃苦的牛劲牛力，不用扬鞭自奋蹄，继续为中华民族伟大复兴辛勤耕耘、勇往直前，在新时代创造新的历史辉煌！通过交互式学习和考核，让学生在“三牛”精神形成的历史背景与过程中学习理解“三牛”精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/b963fc67-c556-432f-aa03-028afd850b3f.png",
            "product_info_bg": "http://resouce.cdzyhd.com/a7039a50-e6fe-4285-941c-f5de0050a81f.png",
            "product_icon": "http://resouce.cdzyhd.com/d04bba65-dd51-4a15-834a-907238d3b453.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "丝路精神",
            "subName": "讲述2000多年前，我们的先辈筚路蓝缕，穿越草原沙漠，开辟出联通亚欧非的陆上丝绸之路；",
            "subName_h5": "讲述2000多年前，我们的先辈筚路蓝缕，穿越草原沙漠，开辟出联通亚欧非的陆上丝绸之路；",
            "description": "讲述2000多年前，我们的先辈筚路蓝缕，穿越草原沙漠，开辟出联通亚欧非的陆上丝绸之路；",
            "experimentDescription": "讲述2000多年前，我们的先辈筚路蓝缕，穿越草原沙漠，开辟出联通亚欧非的陆上丝绸之路；扬帆远航，穿越惊涛骇浪，闯荡出连接东西方的海上丝绸之路，和党的十八大以来，以习近平同志为核心的党中央结合新的历史条件，继承和发扬丝路精神，提出“一带一路”倡议，唤起了沿线国家的历史记忆，赋予古丝绸之路以全新的时代内涵的故事。通过交互式学习和考核，让学生在丝路精神形成的历史背景与过程中学习理解丝路精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/ed70d37d-c66a-4d09-8974-ade34fa3dfce.png",
            "product_info_bg": "http://resouce.cdzyhd.com/328208a5-ee7f-43e8-8010-b489f4216031.png",
            "product_icon": "http://resouce.cdzyhd.com/ad8634f8-c746-48d7-82c9-9ca57c26f142.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "太行精神",
            "subName": "讲述太行精神是国家和民族处于危亡的关键时刻，中国共产党人领导太行儿女展现的勇敢顽强、",
            "subName_h5": "讲述太行精神是国家和民族处于危亡的关键时刻，中国共产党人领导太行儿女展现的勇敢顽强、",
            "description": "讲述太行精神是国家和民族处于危亡的关键时刻，中国共产党人领导太行儿女展现的勇敢顽强、",
            "experimentDescription": "讲述太行精神是国家和民族处于危亡的关键时刻，中国共产党人领导太行儿女展现的勇敢顽强、不畏艰难的革命英雄主义精神，是在极其艰苦的条件下展现的百折不挠、艰苦奋斗的精神，是为人民利益展现的勇于牺牲、乐于奉献精神的故事。通过交互式学习和考核，让学生在太行精神形成的历史背景与过程中学习理解太行精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/6d378340-94f3-4c88-858f-89b42231575d.png",
            "product_info_bg": "http://resouce.cdzyhd.com/b30b5053-d8d0-4afe-8931-25134b4c45e8.png",
            "product_icon": "http://resouce.cdzyhd.com/ecbe99da-7e62-4d9e-84a6-7d9ccf693c0f.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "探月精神",
            "subName": "讲述面对复杂未知的地月空间环境，面对深远空间的测控通信等难题，从2004年1月我国探月工程立项开始",
            "subName_h5": "讲述面对复杂未知的地月空间环境，面对深远空间的测控通信等难题，从2004年1月我国探月工程立项开始",
            "description": "讲述面对复杂未知的地月空间环境，面对深远空间的测控通信等难题，从2004年1月我国探月工程立项开始",
            "experimentDescription": "讲述面对复杂未知的地月空间环境，面对深远空间的测控通信等难题，从2004年1月我国探月工程立项开始，参与研制建设的全体人员不畏艰难、勇于创新，创造了月球探测的中国奇迹，孕育形成了追逐梦想、勇于探索、协同攻坚、合作共赢的探月精神的故事。通过交互式学习和考核，让学生在探月精神形成的历史背景与过程中学习理解探月精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/ac5ae925-4e9e-480a-8ed8-bcf810bfaeda.png",
            "product_info_bg": "http://resouce.cdzyhd.com/4ab07a66-3535-4756-8080-74e4f4f6f911.png",
            "product_icon": "http://resouce.cdzyhd.com/8ab793ec-afd5-41bf-b454-b9edd93f4a88.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "脱贫攻坚精神",
            "subName": "讲述党的十八大以来，习近平总书记站在全面建成小康社会、实现中华民族伟大复兴中国梦的战略高度",
            "subName_h5": "讲述党的十八大以来，习近平总书记站在全面建成小康社会、实现中华民族伟大复兴中国梦的战略高度",
            "description": "讲述党的十八大以来，习近平总书记站在全面建成小康社会、实现中华民族伟大复兴中国梦的战略高度",
            "experimentDescription": "讲述党的十八大以来，习近平总书记站在全面建成小康社会、实现中华民族伟大复兴中国梦的战略高度，把脱贫攻坚摆在治国理政突出位置，提出一系列新思想新观点，作出一系列新决策新部署，推动中国减贫事业取得巨大成就，对世界减贫进程作出了重大贡献的故事。通过交互式学习和考核，让学生在脱贫攻坚精神形成的历史背景与过程中学习理解脱贫攻坚精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/c19b10bd-56ce-4919-b014-63828d2cc807.png",
            "product_info_bg": "http://resouce.cdzyhd.com/07f4ed39-2a7a-4dd5-8e5c-626febb5dc49.png",
            "product_icon": "http://resouce.cdzyhd.com/9c971288-8415-4d76-938c-b1ad88f33109.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "王杰精神",
            "subName": "讲述1965年7月，王杰在组织民兵训练时突遇炸药包意外爆炸。危急关头，年仅23岁的他为保护在场的另外12名民兵",
            "subName_h5": "讲述1965年7月，王杰在组织民兵训练时突遇炸药包意外爆炸。危急关头，年仅23岁的他为保护在场的另外12名民兵",
            "description": "讲述1965年7月，王杰在组织民兵训练时突遇炸药包意外爆炸。危急关头，年仅23岁的他为保护在场的另外12名民兵",
            "experimentDescription": "讲述1965年7月，王杰在组织民兵训练时突遇炸药包意外爆炸。危急关头，年仅23岁的他为保护在场的另外12名民兵，用身体扑向炸药包，挽救了其他人的生命，自己却以身殉职，壮烈牺牲。这纵身一跃，以生命践行了他“一不怕苦、二不怕死”的铮铮誓言的故事。通过交互式学习和考核，让学生在王杰精神形成的历史背景与过程中学习理解王杰精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/db429e6a-5e3b-4b33-a5bb-77916b91c96a.png",
            "product_info_bg": "http://resouce.cdzyhd.com/85a89e7a-07b4-495c-8360-492db8b79785.png",
            "product_icon": "http://resouce.cdzyhd.com/53d662c6-161e-4e0d-8aff-d4b564f3526d.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "西迁精神",
            "subName": "讲述“西迁精神”是在1956年交通大学由上海迁往西安的过程中，生发出来的一种宝贵的精神财富，",
            "subName_h5": "讲述“西迁精神”是在1956年交通大学由上海迁往西安的过程中，生发出来的一种宝贵的精神财富，",
            "description": "讲述“西迁精神”是在1956年交通大学由上海迁往西安的过程中，生发出来的一种宝贵的精神财富，",
            "experimentDescription": "讲述“西迁精神”是在1956年交通大学由上海迁往西安的过程中，生发出来的一种宝贵的精神财富，孕育形成了以“胸怀大局、无私奉献，弘扬传统、艰苦创业”为精神内涵的故事。通过交互式学习和考核，让学生在西迁精神形成的历史背景与过程中学习理解西迁精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/98f18949-8ce4-43ab-ab13-ae8c2c9d234b.png",
            "product_info_bg": "http://resouce.cdzyhd.com/0fdb3fd3-9710-4701-a434-4b6d138fa1e1.png",
            "product_icon": "http://resouce.cdzyhd.com/f2066826-f176-4b1a-82d4-f05bcd0ce1b7.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "新时代北斗精神",
            "subName": "讲述二十六载风雨兼程、九千日夜集智攻关，北斗人秉承航天报国、科技强国使命情怀，",
            "subName_h5": "讲述二十六载风雨兼程、九千日夜集智攻关，北斗人秉承航天报国、科技强国使命情怀，",
            "description": "讲述二十六载风雨兼程、九千日夜集智攻关，北斗人秉承航天报国、科技强国使命情怀，",
            "experimentDescription": "讲述二十六载风雨兼程、九千日夜集智攻关，北斗人秉承航天报国、科技强国使命情怀，团结协作、顽强拼搏、勠力创新、攻坚克难，实现了从无到有、从有到优、从区域到全球的历史性跨越，打造出我国迄今为止规模最大、覆盖范围最广、服务性能最高、与百姓生活关联最紧密的巨型复杂航天系统的故事。通过交互式学习和考核，让学生在新时代北斗精神形成的历史背景与过程中学习理解新时代北斗精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/947b5713-2332-4ea5-8270-a5b67409aca5.png",
            "product_info_bg": "http://resouce.cdzyhd.com/cae0dd98-5116-4467-80e6-aa923eee8215.png",
            "product_icon": "http://resouce.cdzyhd.com/45559097-3bed-43c2-8ca3-cb32a872419f.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "延安精神",
            "subName": "讲述陕西人民传承红色基因，积极投身革命、建设、改革的生动实践，推动三秦大地发生了翻天覆地的变化，",
            "subName_h5": "讲述陕西人民传承红色基因，积极投身革命、建设、改革的生动实践，推动三秦大地发生了翻天覆地的变化，",
            "description": "讲述陕西人民传承红色基因，积极投身革命、建设、改革的生动实践，推动三秦大地发生了翻天覆地的变化，",
            "experimentDescription": "讲述陕西人民传承红色基因，积极投身革命、建设、改革的生动实践，推动三秦大地发生了翻天覆地的变化，让陕甘革命根据地成为党中央和中央红军长征的落脚点、八路军奔赴抗日前线的出发点，使党中央在延安战斗生活了13年，培育形成了光照千秋的延安精神的故事。通过交互式学习和考核，让学生在延安精神形成的历史背景与过程中学习理解延安精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/d03ecaa2-1ec0-4258-9c12-c3d1536cda1d.png",
            "product_info_bg": "http://resouce.cdzyhd.com/10ed5700-9259-468e-888e-fc38b72bdac5.png",
            "product_icon": "http://resouce.cdzyhd.com/f7cfb646-70ee-40d5-bc18-d3ae94304658.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "张思德精神",
            "subName": "讲述1944年9月5日，炭窑坍塌的危急时刻，战士张思德奋力把战友推出洞去，",
            "subName_h5": "讲述1944年9月5日，炭窑坍塌的危急时刻，战士张思德奋力把战友推出洞去，",
            "description": "讲述1944年9月5日，炭窑坍塌的危急时刻，战士张思德奋力把战友推出洞去，",
            "experimentDescription": "讲述1944年9月5日，炭窑坍塌的危急时刻，战士张思德奋力把战友推出洞去，将生命定格在了29岁。在他的追悼会上，毛泽东发表了《为人民服务》的著名演讲，对张思德的思想道德、理想追求和奋斗足迹进行了充分肯定和高度概括。孕育形成了“全心全意为人民服务”为精神内涵的故事。通过交互式学习和考核，让学生在张思德精神形成的历史背景与过程中学习理解张思德精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/b0f775ad-ffb6-4a87-84da-fb3753fd6291.png",
            "product_info_bg": "http://resouce.cdzyhd.com/4b47c150-34c5-46ca-88a3-28819ce4bc68.png",
            "product_icon": "http://resouce.cdzyhd.com/75feddf1-b6e8-44fb-8544-be2419e1eefc.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "照金精神",
            "subName": "讲述中国共产党在领导人民创建西北革命根据地的过程中，形成了伟大的西北根据地革命精神——照金精神",
            "subName_h5": "讲述中国共产党在领导人民创建西北革命根据地的过程中，形成了伟大的西北根据地革命精神——照金精神",
            "description": "讲述中国共产党在领导人民创建西北革命根据地的过程中，形成了伟大的西北根据地革命精神——照金精神",
            "experimentDescription": "讲述中国共产党在领导人民创建西北革命根据地的过程中，形成了伟大的西北根据地革命精神——照金精神，它是西北革命根据地得以存在和发展的精神动力。孕育形成了以“不怕牺牲，顽强拼搏的英雄气概，独立自主、开拓进取的创新勇气，从实际出发、密切联系群众的工作作风”的精神内涵。通过交互式学习和考核，让学生在照金精神形成的历史背景与过程中学习理解照金精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/d512f7ea-2986-4ce9-a3e3-2b552e3174b6.png",
            "product_info_bg": "http://resouce.cdzyhd.com/254c781a-2851-49f9-9533-2b1ea9739019.png",
            "product_icon": "http://resouce.cdzyhd.com/5c4560a6-445a-4227-863c-632fb5ebd4c3.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "遵义会议精神",
            "subName": "讲述遵义会议作为我们党历史上一次具有伟大转折意义的重要会议，在把马克思主义基本原理同中国具体实际相结合、",
            "subName_h5": "讲述遵义会议作为我们党历史上一次具有伟大转折意义的重要会议，在把马克思主义基本原理同中国具体实际相结合、",
            "description": "讲述遵义会议作为我们党历史上一次具有伟大转折意义的重要会议，在把马克思主义基本原理同中国具体实际相结合、",
            "experimentDescription": "讲述遵义会议作为我们党历史上一次具有伟大转折意义的重要会议，在把马克思主义基本原理同中国具体实际相结合、坚持走独立自主道路、坚定正确的政治路线和政策策略、建设坚强成熟的中央领导集体等方面，留下宝贵经验和重要启示，凝练出永放光芒的遵义会议精神的故事。通过交互式学习和考核，让学生在遵义会议精神形成的历史背景与过程中学习理解遵义会议精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/884d3971-a1ad-4a9c-84cd-e1089163e578.png",
            "product_info_bg": "http://resouce.cdzyhd.com/f98b9d85-04e5-473a-8d40-d7897a36c6a3.png",
            "product_icon": "http://resouce.cdzyhd.com/53752e90-4945-4675-8ed0-8ed0722204b2.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "红岩精神",
            "subName": "讲述在抗日战争和解放战争初期那段风雨如磐的岁月中，中共中央南方局老一辈无产阶级革命家、",
            "subName_h5": "讲述在抗日战争和解放战争初期那段风雨如磐的岁月中，中共中央南方局老一辈无产阶级革命家、",
            "description": "讲述在抗日战争和解放战争初期那段风雨如磐的岁月中，中共中央南方局老一辈无产阶级革命家、",
            "experimentDescription": "讲述在抗日战争和解放战争初期那段风雨如磐的岁月中，中共中央南方局老一辈无产阶级革命家、共产党员和革命志士以崇高的思想境界、坚定的理想信念、巨大的人格力量和浩然革命正气影响着许许多多的后来者。解放战争时期，在南方局教育和培养之下成长起来的江竹筠、陈然等共产党员，经受住种种酷刑折磨，为中国人民的解放事业献出了宝贵生命，用鲜血和生命凝结成伟大的红岩精神的故事。通过交互式学习和考核，让学生在红岩精神形成的历史背景与过程中学习理解红岩精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/e3b0a807-6f03-4493-8de9-13b8efd2db46.png",
            "product_info_bg": "http://resouce.cdzyhd.com/17130820-b027-4d18-9924-631b52eac8d3.png",
            "product_icon": "http://resouce.cdzyhd.com/6bee7be4-bba0-4ebd-8edd-7eb08ce5db33.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "抗震救灾精神",
            "subName": "讲述2008年5月12日，8.0级汶川特大地震骤然发生，造成近10万同胞罹难或失踪，面对严重灾情，在中国共产党的坚强领导下，",
            "subName_h5": "讲述2008年5月12日，8.0级汶川特大地震骤然发生，造成近10万同胞罹难或失踪，面对严重灾情，在中国共产党的坚强领导下，",
            "description": "讲述2008年5月12日，8.0级汶川特大地震骤然发生，造成近10万同胞罹难或失踪，面对严重灾情，在中国共产党的坚强领导下，",
            "experimentDescription": "讲述2008年5月12日，8.0级汶川特大地震骤然发生，造成近10万同胞罹难或失踪，面对严重灾情，在中国共产党的坚强领导下，中华民族展现了万众一心、众志成城，不畏艰险、百折不挠，以人为本、尊重科学的伟大抗震救灾精神，最终迸发出气壮山河、感天动地的巨大力量，赢得了抗震救灾的伟大胜利的故事。通过交互式学习和考核，让学生在抗震救灾精神形成的历史背景与过程中学习理解抗震救灾精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/45600e17-4f6d-4cb9-9a0c-e272c20ea141.png",
            "product_info_bg": "http://resouce.cdzyhd.com/699b2fd0-88bc-4844-8abd-f916c7387742.png",
            "product_icon": "http://resouce.cdzyhd.com/c7e0e74e-0318-435a-931f-e868b7795a36.png"
          },
          {
            "id": 2,
            sample: true,
            "name": "两路精神",
            "subName": "讲述从60多年前，西藏和平解放后，中国人民解放军、四川和青海等省各族人民群众以及工程技术人员组成了11万人的筑路大军，",
            "subName_h5": "讲述从60多年前，西藏和平解放后，中国人民解放军、四川和青海等省各族人民群众以及工程技术人员组成了11万人的筑路大军，",
            "description": "讲述从60多年前，西藏和平解放后，中国人民解放军、四川和青海等省各族人民群众以及工程技术人员组成了11万人的筑路大军，",
            "experimentDescription": "讲述从60多年前，西藏和平解放后，中国人民解放军、四川和青海等省各族人民群众以及工程技术人员组成了11万人的筑路大军，在极为艰苦的条件下奋勇拼搏，3000多名英烈捐躯高原，于1954年建成了总长4360公里的川藏、青藏公路，结束了西藏没有现代公路的历史，在“人类生命禁区”的“世界屋脊”创造了公路建设史上的奇迹，铸造了一不怕苦、二不怕死，顽强拼搏、甘当路石，军民一家、民族团结的“两路”精神的故事。通过交互式学习和考核，让学生在“两路”精神形成的历史背景与过程中学习理解“两路”精神的定义、内涵及未来影响；",
            "product_bg": "http://resouce.cdzyhd.com/60fcb36f-10cf-4fee-b627-d92ebea09617.png",
            "product_info_bg": "http://resouce.cdzyhd.com/c5e978b9-51cc-43e8-a073-9f61864938b1.png",
            "product_icon": "http://resouce.cdzyhd.com/b1437b92-2b4f-43b7-8074-02a9ec56ef75.png"
          },
        ]
      },
      experimentInfo: {
        dialog: false,
        experiment: {},
        list: []
      },
      memberActive: "1",
      memberList: [
        {
          list: []
        },
        {
          list: []
        },
      ],
      member: {
        dialog: false,
        info: {}
      },
      linkConfig: {
        list: [
          {
            "id": 1661270247796,
            "img": "http://resouce.cdzyhd.com/521ae478-f177-44e4-8fe7-8ea79d214a99.jpg",
            "name": "重庆城市管理职业学院马克思主义学院",
            "url": "https://www.cqc.edu.cn/mks/main.htm"
          },
          {
            "id": 1662270247796,
            "img": "http://resouce.cdzyhd.com/d23cf15e-2e72-4e9f-8777-b23f55fb824c.png",
            "name": "成都智云鸿道",
            "url": "https://www.cdzyhd.com/"
          }
        ]
      },
    }
  },
  created() {
    if (isMobile()) {
      window.location.href = H5_URL + this.$route.path
    }
  },
  mounted() {
    document.title = this.webConfig.webName
    // 判断是不是在移动端
    this.getExperimentConfig();
  },
  methods: {
    clickVideoImg(){
      window.open("https://wx.vzan.com/live/m/video/detail?id=39904&zbid=537831855")
    },
    // 获取产品配置
    async getExperimentConfig() {
      // 要获取的实验列表，和自定义属性
      let experimentList = [
        {id: "1680005674942", name: "习近平总书记在重庆的足迹"},
        {id: "1706755553875", name: "重庆第三保育院"},
        {id: "1618478479657", name: "中共一大"},
        {id: "1617350115033", name: "飞夺泸定桥"},
        {id: "1666665692980", name: "劳模精神"},
        {id: "1666665687726", name: "职业理想和职业道德"},
      ]
      let list = []
      for (let i = 0; i < experimentList.length; i++) {
        let id = experimentList[i]["id"]
        let experiment = await OfficialWebExperimentModel.getOneExperimentConfig(id)
        let experimentLast=Object.assign({},experiment,experimentList[i])
        // 合并自定义项
        list.push(experimentLast)
        this.$set(this.experiments, "list", list)
      }
      list=list.concat(this.experiments.list_2)
      this.$set(this.experiments, "list", list)
    },
    // 点击首页导航
    clickNavLi(v) {
      this.navIndex = v;
      switch (v) {
        case "1":
          window.location.href = "#center"
          break;
        case "2":
          window.location.href = "#experiment"
          break;
        case "3":
          window.location.href = "#member"
          break;
        case "4":
          window.location.href = "#link"
          break;
        case "5":
          window.location.href = "/login?schoolId=99450849243172864"
          break;
      }
    },
    // 点击团队成员
    clickMember(item, index) {
      this.$set(this.member, "info", item)
      this.member.dialog = true
    },
    // 点击友情链接
    clickLink(item) {
      window.open(item.url)
    },
    // 实验项目方法集
    ExperimentMethods() {
      let $this = this;
      return {
        clickViewExperiment(experiment) {
          $this.experimentInfo.dialog = true
          document.body.style.overflow = 'hidden'
          $this.experimentDialogEnterBtn = false
          $this.$set($this.experimentInfo, "experiment", experiment);
          $this.experimentDialogPicList = [
            experiment.product_info_img_1,
            experiment.product_info_img_2,
            experiment.product_info_img_3,
            experiment.product_info_img_4,
          ]
          if (!experiment.product_video && experiment.product_info_img) {
            $this.experimentDialogPicList.push(experiment.product_info_img)
          }
          setTimeout(() => {
            $(".experiment-dialog .bg").css("background-image", `url(${experiment.product_info_bg})`)
          }, 100)
        },
        // 关闭实验弹窗
        closeExperimentDialog() {
          $this.experimentInfo.dialog = false
          document.body.style.overflow = ''
        },
        // 播放视频
        playVideo(e) {
          // this.videoPlay = true
          // document.querySelector("#v1").play()
          $this.experimentInfo.experimentVideoDialog = true
        }
      }
    }
  }
}
</script>
<style>
.el-menu--horizontal {
  border-bottom: none !important;
}

.el-menu--horizontal > .el-menu-item {
  margin-right: 20px !important;
  font-size: 18px !important;
  color: #777;
  letter-spacing: 2px;
  font-weight: 500;
}

.el-menu--horizontal > .el-menu-item:hover {
  transition: all 0.2s linear;
  transform: scale(1.1);
}

.el-menu--horizontal > .el-submenu .el-submenu__title {
  font-size: 16px !important;
  letter-spacing: 2px;
}

.el-menu--horizontal .el-menu .el-menu-item {
  font-size: 14px !important;
  text-align: center;
}
</style>
<style scoped lang="less">
@import '../../style/app.less';

.web-container {
  //background-color: #f2f2f2;
}

.content-container {
  &:hover {
    .el-divider__text {
      color: #4093f9;
      font-size: 24px;
    }

  }
}

.content-title {
  margin-top: 40px;

  .el-divider__text {
    font-size: 22px;
    color: #777;
    font-weight: 450;
    text-align: center;
    margin-bottom: 20px;
  }

}

.page-header {
  padding: 20px 0px;
  z-index: 1999;
  background-size: cover;
  background-position: 50% 50%;
  -webkit-backface-visibility: hidden;
  background-image: url("http://resouce.cdzyhd.com/68afb18d-99b6-43cc-8961-8095d1d7b466.png");

  .header-container {
    width: 1280px;
    margin: 0 auto;
  }

  .web-title {
    .logo {
      width: 284px;
      height: 56px;
      margin-right: 10px;
      cursor: pointer;
    }

    .title-name {
      font-size: 28px;
      border-left: 2px solid #fff;
      margin-left: 16px;
      padding-left: 16px;
      color: #fff;
    }
  }
}

.nav-container {
  text-align: right;
  width: 1280px;
  margin: 0 auto;

  .li {
    font-size: 16px;
    background-color: #fff;
    color: #333;
    padding: 10px 30px;
    display: inline-block;
    cursor: pointer;
  }

  .li.active {
    background: #7f1b16;
    color: #fff;
  }
}

.focus-container {

}

.video-container{
  // 保持中间显示效果 视频要求 2560*780
  video {
    width: 100%;
    //height: 100vh;
    object-fit: fill;
  }
}

.li-container {
  margin-bottom: 50px;

  .content-html {
    margin-top: 45px;

    text-indent: 2em;
    font-size: 15px;
    color: #666;
    line-height: 25px;
    letter-spacing: 1px;
  }
}

.experiment-list {
  margin-top: 55px;

  .sub-list {
    .sub-li {
      margin-bottom: 20px;
      margin-right: 40px;
      position: relative;

      &:nth-child(4n+4) {
        margin-right: 0px;
      }

      .hide {
        visibility: hidden;
      }

      &:hover {
        .bg {
          img {
            transition: all 0.5s linear;
            transform: scale(1.1);
          }
        }

        .info {
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

          .avatar {
            height: 60px;
            width: 60px;
            margin-top: -30px;
            opacity: 0;
          }


          .des-main {
            opacity: 0;
          }

          .title {
            margin-top: -30px;
            margin-bottom: 0px;

          }

          .des {
            display: -webkit-box;
          }

          .buttons {

            display: block;
          }
        }

      }


      img.left-icon {
        position: absolute;
        width: 60px;
        height: 60px;
        top: -10px;
        left: -10px;
        z-index: 10;
      }

      .bg {
        z-index: 1;
        width: 290px;
        height: 183px;
        overflow: hidden;
        position: relative;

        img {
          width: 290px;
          height: 183px;
          display: block;
          transition: all 0.5s linear;
          transform: scale(1);

        }
      }

      .info {
        position: relative; // 解决margin-top负数 和同级div的z-index问题
        padding: 25px;
        height: 180px;
        z-index: 2;
        width: 270px;
        margin: 0 auto;
        margin-top: -60px;
        text-align: center;
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-bottom: 3px solid transparent;
        transition: all 0.2s linear;
        overflow: hidden;

        .avatar {
          display: inline-block;
          width: 60px;
          height: 60px;
          position: relative;
          margin-top: 0px;
          transition: all 0.2s linear;
          border-radius: 4px;
          box-shadow: 2px 2px 5px 1px #dedede;
        }

        .title {
          position: relative;
          font-size: 16px;
          font-weight: bold;
          margin-top: 18px;
          margin-bottom: 10px;
          transition: all 0.2s linear;
        }

        .des-main {
          transition: all 0.2s linear;
          color: #666;
          font-size: 14px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }

        .des {
          font-weight: 300;
          font-size: 14px;
          display: none;
          color: #666;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        .buttons {
          display: none;
          text-align: center;
          margin-top: 12px;
        }

        .button {
          width: 100px;
          line-height: 28px;
          border: 1px solid #d8d8d8;
          border-radius: 15px;
          text-align: center;
          font-size: 14px;
          color: #666666;
          position: relative;
          cursor: pointer;
          transition: all 0.2s linear;
          text-decoration: none;
          padding: 5px 20px;

          &:hover {
          }
        }

      }
    }

    .more-li{
      .info{
        background-image: url("http://resouce.cdzyhd.com/c8db5de2-7b9d-46f2-a517-35af74946245.png");
      }
    }
  }
}

.experiment-dialog {
  border-radius: 4px;
  z-index: 2000; // 解决被遮挡问题
  .dialog-box {
    border-radius: 4px;
  }


  .bg {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 100%;
    height: 330px;
    background-size: cover;
    background-position: 50% 50%;
  }

  .close-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .info-box {
    padding: 0px 80px;
  }

  .header-box {
    position: relative;
    margin-top: -115px;

    .avatar {
      width: 130px;

      border-radius: 4px;
      box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
      margin-right: 20px;
    }

    .hide {
      visibility: hidden;
      width: 0px;
      height: 130px;
    }

    .name-box {
      .name {
        font-size: 33px;
        font-weight: bold;
        line-height: 44px;
        color: #FFFFFF;
        text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
        opacity: 0.9;
      }

      .series {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: #FFFFFF;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
        opacity: 0.9;
        margin-bottom: 8px;
      }
    }

    .enter-btn {
      margin-left: 300px;
      width: 97px;
      height: 29px;
      background-size: cover;
      background-position: 50% 50%;
      -webkit-backface-visibility: hidden;
      //background-image: url("../assets/product/dialog/enter-btn.png");
      cursor: pointer;
    }

    .view-now-btn {
      display: inline-block;
      padding: 10px 30px;
      background-color: #ff3829;
      color: #fff;
      font-size: 14px;
      margin-left: 200px;
      border-radius: 20px;
    }

  }

  .description-box {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: #333333;
    margin-top: 20px;

    .title {
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      color: #333;
    }

    .content {
      margin-top: 5px;
      font-size: 14px;
      text-indent: 2em;
      font-weight: 400;
      line-height: 19px;
      color: #333333;
    }
  }

  .view-box {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 35px;

    .video-box {
      width: 337px;
      height: 223px;
      position: relative;

      video {
        position: absolute;
        left: 0;
        top: 0;
        width: 337px;
        height: 223px;
        object-fit: fill;
        z-index: 1;
      }

      .play-btn {
        position: absolute;
        // 上下左右居中
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 54px;
        height: 54px;
        cursor: pointer;
        z-index: 2;
      }
    }

    .video-box-no {
      width: 337px;
      height: 223px;
      background-size: cover;
      background-position: 50% 50%;
      -webkit-backface-visibility: hidden;
    }

    .img-box {
      width: 695px;

      .img {
        width: 334px;
        height: 106px;
        cursor: pointer;

        &:nth-child(1), &:nth-child(2) {
          margin-bottom: 11px;
        }
      }
    }
  }
}

.member-list {
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 55px;
  width: 1240px;

  .el-card {
    border: none;
  }

  .avatar-list {
    .avatar-li {
      cursor: pointer;
      margin-top: 15px;
      margin-left: 34px;
      margin-right: 0px;

      .el-image {
        background-size: cover;
        background-position: 50% 50%;
        -webkit-backface-visibility: hidden;
        opacity: 1;
        transform: scale(1);
        cursor: pointer;


        &:hover {
          transition: all 0.3s linear;
          transform: scale(1.1);
        }
      }

      .name {
        text-align: center;
        margin-top: 15px;
        color: #888;
        font-size: 15px;
      }
    }
  }
}

.member-dialog {
  .history {
    text-align: left;
    font-size: 14px;
    color: #888;
  }

  .success {
    text-align: left;
    font-size: 14px;
    color: #888;
  }
}

// 友情链接
.link-container {
  margin-bottom: 45px;

  .list {
    margin-top: 40px;
    text-align: center;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 285px);
    grid-gap: 10px;

    .name {
      margin-top: 10px;
      color: #777;
      font-size: 15px;
    }
  }


  .li {
    width: 285px;
    height: 140px;
    margin-top: 10px;
    //margin-right: 10px;
    background-size: cover;
    background-position: 50% 50%;
    -webkit-backface-visibility: hidden;
    opacity: 1;
    transform: scale(1);
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);

    &:hover {
      transition: all 0.3s linear;
      transform: scale(1.03);
    }
  }

  .more {
    text-align: center;
    cursor: pointer;
    transition: all 0.3s linear;
    width: 100px;
    margin: 0 auto;
    margin-top: 30px;
    color: #707070;

    &:hover {
      transform: scale(1.1);
    }

    span {
      font-weight: 400;
      line-height: 21px;
      color: #707070;
      font-size: 16px;
      margin-right: 3px;
    }

    img {
      width: 22px;
      height: 22px;
      display: inline-block;
      margin-left: 4px;
    }
  }
}

.page-footer {
  background-color: #821b17;
  padding: 20px 0px;

  .info-container {
    .logo {
      width: 70px;
      height: 70px;
      cursor: pointer;
      display: none;
    }

    .infos {
      text-align: center;
      color: #fff;
      font-size: 14px;

      span {
        margin-right: 10px;
      }
    }
  }
}

.back-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99999;

  img {
    width: 40px;
    height: 40px;
  }
}
</style>
